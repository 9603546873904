<template>
  <table class="table">
    <thead>
    <tr>
      <th>序号</th>
      <th>税目</th>
      <th>税率</th>
      <th>合同总金额（元）</th>
      <th>调整金额</th>
      <th>计税依据</th>
      <th>应纳税额</th>
      <th>合同数量（份）</th>
      <th>年度申报计税依据（元）</th>
      <th>差异金额（元）</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row,index) in t" :key="index">
      <template v-if="index!=16">


        <td>{{ index + 1 }}</td>
        <td>{{ row['taxItemName'] }}</td>
        <td>{{ row['taxItemRate'] }}</td>
        <td>{{ row['contractAmountTotal'] |currency }}</td>
        <td v-if="index==13||index==14||index==15">*</td>
        <td v-else><input type="number" v-model.number="row['adjustAmount']" class="input"
                          @input="handleInput(index)"></td>
        <td>{{ row['taxBasis'] |currency }}</td>
        <td>{{ row['taxPayable'] |currency }}</td>
        <td>{{ row['contractCount'] }}</td>
        <td>{{ row['yhsAmountTotal'] }}</td>
        <td>{{ row['balance'] }}</td>
      </template>
      <template v-else>
        <td class="summary" style="font-weight: bold">合计</td>
        <td class="summary"></td>
        <td class="summary"></td>
        <td class="summary">{{ row['contractAmountTotal'] |currency }}</td>
        <td class="summary">{{ row['adjustAmount'] |currency }}</td>
        <td class="summary">{{ row['taxBasis'] |currency }}</td>
        <td class="summary">{{ row['taxPayable'] |currency }}</td>
        <td class="summary">{{ row['contractCount'] }}</td>
        <td class="summary">{{ row['yhsAmountTotal'] |currency }}</td>
        <td class="summary">{{ row['balance'] |currency }}</td>
      </template>
    </tr>


    </tbody>
  </table>
</template>

<script>
import {round} from "@/utils/format";
import {cloneDeep} from "lodash"
import {editStampTaxYear} from "@/api/yinhuashui";


export default {
  name: "YinhuashuiTable",
  props: {
    table: Array
  },
  filters: {
    currency: function (val) {
      const options = {
        minimumFractionDigits: 2, useGrouping: true
      };
      if (val != null) {
        val = round(val, 2)
        return val.toLocaleString('zh-CN', options);
      } else {
        return val
      }

    }
  },
  data() {
    return {
      t: []
    }
  },
  watch: {
    table: {
      handler: function () {
        if (this.table.length > 0) {
          this.t = cloneDeep(this.table)
          this.reCalculateSumary();

          this.reCalculate()

        }

      },
      immediate: true
    }

  },
  methods: {
    handleInput(index) {
      console.log(this.t[index])
      this.reCalculate()
      setTimeout(() => {
        editStampTaxYear(this.t[index])
      })
    },
    //得到当前的表格内容
    getResult() {
      const result = cloneDeep(this.t);
      //result.pop() //删除合计行
      return result;

    },
    reCalculate() {

      for (let key in this.t) {
        const row = this.t[key]
        this.t[key]['taxBasis'] = round(this.t[key]['contractAmountTotal'] + this.t[key]['adjustAmount'], 2)
        if (key == 15) {
          this.t[key]['taxBasis'] = 0
        }
      }
      this.t[16]['taxBasis'] = 0


      for (let key in this.t) {
        this.t[key]['taxPayable'] = round(this.t[key]['taxItemRate'] * this.t[key]['taxBasis'], 2)
        if (key == 15) {
          this.t[key]['taxPayable'] = 0
        }
      }
      this.t[16]['taxPayable'] = 0

      //差异金额 = 计税依据 -  年度申报计税依据
      for (let key in this.t) {
        this.t[key]['balance'] = round(this.t[key]['taxBasis'] - this.t[key]['yhsAmountTotal'], 2)
      }
      this.t[16]['balance'] = 0

      this.reCalculateSumary();


      //['contractAmountTotal1'] + this.t['contractAmountTotal2'] + this.t['contractAmountTotal3'] + this.t['contractAmountTotal4'] + this.t['contractAmountTotal5'] + this.t['contractAmountTotal6'] + this.t['contractAmountTotal7'] + this.t['contractAmountTotal8'] + this.t['contractAmountTotal9'] + this.t['contractAmountTotal10'] + this.t['contractAmountTotal11'] + this.t['contractAmountTotal12'] + this.t['contractAmountTotal13'] + this.t['contractAmountTotal14'] + this.t['contractAmountTotal15'] + this.t['contractAmountTotal16']
      // this.t['调整金额_合计'] = this.t['调整金额1'] + this.t['调整金额2'] + this.t['调整金额3'] + this.t['调整金额4'] + this.t['调整金额5'] + this.t['调整金额6'] + this.t['调整金额7'] + this.t['调整金额8'] + this.t['调整金额9'] + this.t['调整金额10'] + this.t['调整金额11'] + this.t['调整金额12'] + this.t['调整金额13'] + this.t['调整金额14'] + this.t['调整金额15'] + this.t['调整金额16']
      // this.t['计税依据_合计'] = this.t['计税依据1'] + this.t['计税依据2'] + this.t['计税依据3'] + this.t['计税依据4'] + this.t['计税依据5'] + this.t['计税依据6'] + this.t['计税依据7'] + this.t['计税依据8'] + this.t['计税依据9'] + this.t['计税依据10'] + this.t['计税依据11'] + this.t['计税依据12'] + this.t['计税依据13'] + this.t['计税依据14'] + this.t['计税依据15'] + this.t['计税依据16']
      // this.t['应纳税额_合计'] = round(this.t['应纳税额1'] + this.t['应纳税额2'] + this.t['应纳税额3'] + this.t['应纳税额4'] + this.t['应纳税额5'] + this.t['应纳税额6'] + this.t['应纳税额7'] + this.t['应纳税额8'] + this.t['应纳税额9'] + this.t['应纳税额10'] + this.t['应纳税额11'] + this.t['应纳税额12'] + this.t['应纳税额13'] + this.t['应纳税额14'] + this.t['应纳税额15'] + this.t['应纳税额16'],4)
      // this.t['合同数量_合计'] = this.t['合同数量1'] + this.t['合同数量2'] + this.t['合同数量3'] + this.t['合同数量4'] + this.t['合同数量5'] + this.t['合同数量6'] + this.t['合同数量7'] + this.t['合同数量8'] + this.t['合同数量9'] + this.t['合同数量10'] + this.t['合同数量11'] + this.t['合同数量12'] + this.t['合同数量13'] + this.t['合同数量14'] + this.t['合同数量15'] + this.t['合同数量16']
      this.$forceUpdate()
    },
    reCalculateSumary() {
      this.t[16] = {};
      this.t[16]['contractAmountTotal'] = round(this.t.reduce((a, b) => {
        return a + (b.contractAmountTotal || 0)
      }, 0), 2)

      this.t[16]['adjustAmount'] = round(this.t.reduce((a, b) => {
        return a + (b.adjustAmount || 0)
      }, 0), 2)

      this.t[16]['taxBasis'] = round(this.t.reduce((a, b) => {
        return a + (b.taxBasis || 0)
      }, 0), 2)

      this.t[16]['taxPayable'] = round(this.t.reduce((a, b) => {
        return a + (b.taxPayable || 0)
      }, 0), 2)

      this.t[16]['contractCount'] = round(this.t.reduce((a, b) => {
        return a + (b.contractCount || 0)
      }, 0), 2)

      this.t[16]['yhsAmountTotal'] = round(this.t.reduce((a, b) => {
        return a + (b.yhsAmountTotal || 0)
      }, 0), 2)

      this.t[16]['balance'] = round(this.t.reduce((a, b) => {
        return a + (b.balance || 0)
      }, 0), 2)

      this.t[16]['taxItemName'] = "合计"

    }
  }
}
</script>

<style lang="stylus" scoped>
.table {
  border-collapse: collapse;
  box-shadow: 0px -1px 0px 0px #EEEEEE;
  background #fff;

  td, th {
    padding 5px 5px 5px 12px
    text-align left
    border-right 1px solid #D9D9D9;
    border-bottom 1px solid #EEEEEE;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }

  td:last-child, th:last-child {
    border-right none;
  }

  thead {
    background: #F0F5FF;
  }

  th {
    color: rgba(0, 0, 0, 0.8);
    font-weight bold
  }

  .summary {
    background: #F0F5FF;
  }

  .input {
    border 1px solid #ccc;
    display block
    line-height 2
    width calc(100% - 12px)
    border-radius 5px
    padding 0 10px
    box-sizing border-box
  }

  .input {
    outline none
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
