<template>
  <div class="page-wrapper">
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <div class="main-top">
        <MainHeader>
          <template slot="title">分析结果</template>
        </MainHeader>

        <div class="placeholder"></div>
      </div>
    </div>
    <SaTabs default-active-name="first" class="tabs-section">
      <el-tab-pane label="年度印花税统计" name="first">
        <YinhuashuiTableSwitcher></YinhuashuiTableSwitcher>
      </el-tab-pane>
      <el-tab-pane label="小微企业借款免征印花税" name="second">
        <div class="tip">以下企业工商信息显示为小微企业，可能适用借款合同印花税免税政策，请关注。</div>
        <XQYBorrowTable></XQYBorrowTable>
      </el-tab-pane>
      <el-tab-pane label="申报差异" name="third">
        <div class="tip">以下为台账中借款合同合计金额、与印花税申报计税依据比对的情况，已剔除小微企业的借款金额，请关注差异情况。</div>
        <DifferentTable></DifferentTable>
      </el-tab-pane>
    </SaTabs>

    <SaFooter>

      <button
        class="btn btn-primary btn-next"
        @click="handleBtnNextClick"
      >
        返回
      </button>
    </SaFooter>
  </div>
</template>

<script>
import BreadCrumb from "../../../components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import SaFooter from "../../Test/UploadTable/components/SaFooter";
import SaSteps from "../../components/SaSteps";
import SaTabs from "../../components/SaTabs";
import YinhuashuiTableSwitcher from "./components/YinhuashuiTableSwitcher";
import {getStampTaxYearList} from "../../../api/yinhuashui";
import XQYBorrowTable from "./components/XQYBorrowTable";
import DifferentTable from "./components/DifferentTable";

export default {
  name: "YinHuaShuiResult",
  components: {
    DifferentTable,
    XQYBorrowTable,
    SaFooter,
    BreadCrumb,
    MainHeader,
    SaTabs,
    YinhuashuiTableSwitcher
  },
  props:{
    programId:String
  },
  data() {
    return {
      steps: ['基础信息', '合同台账上传', '印花税申报表上传', '提交成功'],
      stepActive: 0,
      form: {
        companyName: '',
        nsrsbh: '',
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    handleBtnNextClick() {
      this.$router.go(-1)
    },
    init(){
    },

  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  }

  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .section-box {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom 50px

    .title {
      color: rgba(0, 0, 0, 0.8);
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;
    }

    .field {
      margin: 16px 0 0 40px;
      display flex
      align-items center;
    }

    .field:before {
      content: "*";
      color: red;
      position: relative;
      right: 4px;
      top: 1px;
    }

    .label {

      width 100px
    }

    .input {
      width: 40%;
    }

  }

  .tabs-section{
    margin: 16px 30px 0;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .tip {
    font-size: 14px;
    color: #f5222d;
    margin: 16px 0;
  }
}
</style>
