<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <slot></slot>
  </el-tabs>
</template>

<script>
export default {
  name: "SaTabs",
  props: {
    defaultActiveName: String
  },
  data() {
    return {
      activeName: ""
    }
  },
  mounted() {
    this.activeName = this.defaultActiveName
  },
  methods: {
    handleClick() {

    }
  }
}
</script>
<style lang="stylus" scoped>
.tabs-section{

}


>>> >.el-tabs__header {
  margin-bottom 0
  .el-tabs__nav-wrap::after {
    display none
  }
  .el-tabs__nav-scroll {

    margin-top 20px
    padding-top 20px
    display flex;
    justify-content center;

    .el-tabs__active-bar {
      display none;
    }

    .el-tabs__item {
      padding 0 30px !important
      width auto;
      font-size 15px
      height: 49px;
      line-height: 49px;
      text-align: center;
      background: #fff;
      border-radius: 18px 18px 0px 0px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.4);
      margin 0 12px
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.06);
    }

    .el-tabs__item.is-active {
      color: #2f54eb;
      font-weight: 600;
    }

  }
}

>>> >.el-tabs__content {
  background #fff
  padding 20px
  border-radius 8px;
  overflow: visible;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.06);

}


</style>
