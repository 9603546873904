<template>
  <table class="table">
    <thead>
    <tr>
      <th>序号</th>
      <th>小微企业名称</th>
      <th>纳税人识别号</th>
      <th>借款合同总金额（免征印花税）</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row,index) in table" :key="index">


      <td>{{ row['id']  }}</td>
      <td>{{ row['microEntName'] }}</td>
      <td>{{ row['taxNo'] }}</td>
      <td>{{ row['contractAmount'] |currency }}</td>


    </tr>


    </tbody>
  </table>
</template>

<script>
import {getMicroEntList} from "@/api/yinhuashui";
import {round} from "../../../../utils/format";

export default {
  name: "XQYBorrowTable",
  computed: {
    programId() {
      return this.$route.params.programId
    }
  },
  data() {
    return {
      table: []
    }
  },
  filters:{
    currency:function(val){
      const options = {
        minimumFractionDigits: 2, useGrouping: true
      };
      if(val !=null){
        val = round(val,2)
        return val.toLocaleString('zh-CN', options);
      }else {
        return val
      }

    }
  },
  mounted() {
    this.loadTable()
  },
  methods: {
    async loadTable() {
      const res = await getMicroEntList({programId: this.programId})
      this.table = res.data
    }
  }
}
</script>

<style lang="stylus" scoped>
.table {
  border-collapse: collapse;
  box-shadow: 0px -1px 0px 0px #EEEEEE;
  background #fff;
  width 100%

  td, th {
    padding 5px 5px 5px 12px
    text-align left
    border-right 1px solid #D9D9D9;
    border-bottom 1px solid #EEEEEE;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }

  td:last-child, th:last-child {
    border-right none;
  }

  thead {
    background: #F0F5FF;
  }

  th {
    color: rgba(0, 0, 0, 0.8);
    font-weight bold
  }

  .summary {
    background: #F0F5FF;
  }

  .input {
    border 1px solid #ccc;
    display block
    line-height 2
    width calc(100% - 12px)
    border-radius 5px
    padding 0 10px
    box-sizing border-box
  }

  .input {
    outline none
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
